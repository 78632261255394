// extracted by mini-css-extract-plugin
export const overlay = "program-nav-module--overlay--1Wc_H";
export const modal = "program-nav-module--modal--2ikAX";
export const body = "program-nav-module--body--26cy2";
export const header = "program-nav-module--header--2SzOU";
export const closeIcon = "program-nav-module--closeIcon--1-_Dq";
export const mobileActionParent = "program-nav-module--mobileActionParent--WLSMU";
export const mobileAction = "program-nav-module--mobileAction--suBmT";
export const phoneParent = "program-nav-module--phoneParent--3W6Dk";
export const actionParent = "program-nav-module--actionParent--1x9A4";
export const action = "program-nav-module--action--375is";
export const iconMobile = "program-nav-module--iconMobile--NN79M";
export const iconParent = "program-nav-module--iconParent--3s1N8";
export const icon = "program-nav-module--icon--17F9U";
export const phoneNumber = "program-nav-module--phoneNumber--3vXT4";