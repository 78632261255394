import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import * as styles from '@css/modules/bottomBanner.module.scss';

export default function BottomBanner({ node_locale }) {
  const { allContentfulBottomBanner } = useStaticQuery(graphql`
    query BottomBannerQuery {
      allContentfulBottomBanner {
        edges {
          node {
            node_locale
            crisisSupport
            copyright
            links {
              url
              copy
              sameSite
              zendeskLink
            }
          }
        }
      }
    }
  `);
  const { node } = allContentfulBottomBanner.edges.find(
    (edge) => edge.node.node_locale === node_locale,
  );

  function handleClick() {
    window.zE('webWidget', 'setLocale', node_locale);
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  }

  useEffect(() => {
    const buttonColour = getComputedStyle(
      document.documentElement,
    ).getPropertyValue('--primary');
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          button: buttonColour,
        },
      },
    });
    window.zE('webWidget', 'hide');
  }, []);

  useEffect(() => {
    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide');
    });
  });

  return (
    <footer className="p-0 bg-secondary">
      <Col
        className={`${styles.bottomBanner} py-5 text-white d-flex flex-column flex-2xl-row align-items-md-center justify-content-2xl-center justify-content-3xl-between`}
        style={{ flexGrow: 1 }}
      >
        <Row className="mx-0 mb-4 mb-2xl-0" md="auto">
          <p className={styles.bottomBannerText}>{node.crisisSupport}</p>
        </Row>
        <Row md="auto" className="m-0 flex-column flex-2xl-row">
          <Col className="p-0 d-flex flex-column flex-md-row mx-2xl-4">
            {node.links.map((link, i) => (
              <Row key={i} className="mx-0 mx-md-2 mb-2 mb-2xl-0" md="auto">
                {link.zendeskLink &&
                process.env.GATSBY_ZENDESK_WIDGET === 'true' ? (
                  <Button
                    className={`btn-link feedback text-white text-nowrap semi-bold  ${styles.bottomBannerText}`}
                    onClick={() => handleClick()}
                  >
                    {link.copy}
                  </Button>
                ) : (
                  <a
                    className={`text-white text-nowrap semi-bold ${styles.bottomBannerText}`}
                    href={
                      link.sameSite
                        ? link.url
                        : `${process.env.GATSBY_GREENSPACE_URL}${link.url}`
                    }
                    target={link.sameSite ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {link.copy}
                  </a>
                )}
              </Row>
            ))}
          </Col>
          <Col className="p-0 d-flex flex-column flex-md-row justify-content-md-center">
            <Row className="mx-0 mt-3 mt-2xl-0" md="auto">
              <p className={styles.bottomBannerText}>{node.copyright}</p>
            </Row>
          </Col>
        </Row>
      </Col>
    </footer>
  );
}
