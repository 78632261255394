import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import store from 'store';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CheckCircleFill, X } from 'react-bootstrap-icons';

import SaveBookmarkContext from '@components/bookmarks/SaveBookmarkContext';
import sessionStorage from '@util/sessionStorage';

export default function ConfirmSave({ node_locale }) {
  const { allContentfulBookmarksPage } = useStaticQuery(graphql`
    query ConfirmSaveQuery {
      allContentfulBookmarksPage {
        edges {
          node {
            node_locale
            confirmSaveTitle
            confirmSaveCopy {
              childMarkdownRemark {
                html
              }
            }
            confirmSaveConfirm
          }
        }
      }
    }
  `);
  const { node } = allContentfulBookmarksPage.edges.find(
    (edge) => edge.node.node_locale === node_locale,
  );
  const { seen, show, toggleShow, toggleSeen } = useContext(
    SaveBookmarkContext,
  );

  useEffect(() => {
    const hasBeenSeen = sessionStorage.get('seenBookmarkModal');
    const isLocal = store.get('bookmarkStorage') === 'local';
    // setting seen to true will disable this modal
    if (hasBeenSeen || isLocal) toggleSeen(true);
  }, []);

  useEffect(() => {
    if (seen) sessionStorage.set('seenBookmarkModal', true);
  }, [seen]);

  function handleClose() {
    toggleShow(false);
    toggleSeen(true);
  }

  return (
    <Modal show={show && !seen} centered onClose={() => handleClose()}>
      <Button
        variant="outline"
        aria-label="close"
        className="modalCloseButton"
        onClick={handleClose}
      >
        <X size={24} />
      </Button>
      <Modal.Body className="text-center p-5 position-relative">
        <h4 className="text-secondary d-flex align-items-center justify-content-center">
          <CheckCircleFill className="text-secondary mr-2" />
          {node.confirmSaveTitle}
        </h4>
        <div
          className="my-4 text-secondary"
          dangerouslySetInnerHTML={{
            __html: node.confirmSaveCopy.childMarkdownRemark.html,
          }}
        />
        <Button variant="primary px-5 py-2 text-bolder" onClick={handleClose}>
          {node.confirmSaveConfirm}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
