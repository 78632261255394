import React from 'react';

const SaveBookmarkContext = React.createContext({
  seen: false,
  toggleSeen: (seen: boolean) => {},
  show: false,
  toggleShow: (show: boolean) => {},
});

SaveBookmarkContext.displayName = 'Save Bookmark Context';

export default SaveBookmarkContext;
