import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';

import { ChevronDown, Star } from 'react-bootstrap-icons';

import { CrisisTopbar } from '@components/layout/CrisisTopbar';
import locale from '@util/locale';
import { NodeLocale } from '@src/interfaces/NodeLocale';
import * as styles from '@src/css/components/top-nav/navigation-menu.module.scss';
import { LinkedTextData } from '@src/interfaces/LinkedTextData';
import ProgramNavigator from '@components/modals/ProgramNavigator';
import { useMPEvent, MPEventData } from '@src/util/mixpanel';
import { GAEvent } from '@src/util/analytics/GAEvent';
import { SmoothScrollLink } from '../SmoothScrollLink';
import { TooltipTrigger } from '../TooltipTrigger';

interface NavigationMenuProps {
  nodeLocale: NodeLocale;
  page: string;
  translations?: {
    'en-CA': string;
    'fr-CA': string;
  };
}

interface NavigationMenuData {
  nodeLocale: NodeLocale;
  navigationItems: NavigationItemData[];
  signIn: string;
  createAccount: string;
  savedResourcesText: string;
  whereToStartText: string;
  exploreDropdownHeader: string;
  showLanguageToggle: boolean;
  showSavedResources: boolean;
}

interface NavigationItemData {
  id: string;
  title?: string;
  menuItems?: LinkedTextData[];
  text?: string;
  url?: string;
}

export const NavigationMenu = ({
  nodeLocale,
  translations,
}: NavigationMenuProps) => {
  const { allContentfulNavigationMenu } = useStaticQuery<{
    allContentfulNavigationMenu: { edges: { node: NavigationMenuData }[] };
  }>(graphql`
    query NavQuery {
      allContentfulNavigationMenu {
        edges {
          node {
            ...NavigationMenu
          }
        }
      }
    }
  `);
  const { node: navMenuData } = allContentfulNavigationMenu.edges.find(
    (edge) => edge.node.nodeLocale === nodeLocale,
  )!;
  const location = useLocation();
  const to = location.pathname.slice(
    location.pathname.indexOf(nodeLocale) + nodeLocale.length,
  );
  const { trackPageLoad, trackPageInteraction } = useMPEvent();

  const supportedLocales: SupportedLocale[] = [
    {
      code: 'fr-CA',
      name: 'FR',
      slug: translations?.['fr-CA']
        ? `/fr-CA/${translations['fr-CA']}`
        : `/fr-CA${to}`,
    },
    {
      code: 'en-CA',
      name: 'EN',
      slug: translations?.['en-CA']
        ? `/en-CA/${translations['en-CA']}`
        : `/en-CA${to}`,
    },
  ];

  const [showProgramNav, setShowProgramNav] = useState(false);
  function clickContactProgramNavigator() {
    const eventData: MPEventData = {
      language: nodeLocale.toLowerCase() === 'en-ca' ? 'en-ca' : 'fr-ca',
      url: window?.location.pathname,
      url_name: 'self_guided_care_navigator_modal',
    };
    trackPageLoad(eventData);
    trackPageInteraction({
      ...eventData,
      element: 'program navigator button',
      action: 'open',
    });
    GAEvent.landingPage.openProgramNavigator();
  }

  return (
    // We reference this to get anchors in the right spot, so we need this ID
    <header className="sticky-on-lg" id="top-sticky-nav">
      {/* Program Navigator Modal */}
      <ProgramNavigator
        node_locale={nodeLocale}
        show={showProgramNav}
        setShow={setShowProgramNav}
      />
      <CrisisTopbar nodeLocale={nodeLocale} link={`/${nodeLocale}/crisis`} />
      <Navbar
        className={`${styles.navbar} shadow`}
        variant="light"
        bg="white"
        expand="lg"
        collapseOnSelect
      >
        <Container className={styles.container}>
          <Navbar.Brand>
            <Link className="d-block" to={`/${nodeLocale}`}>
              <img
                className={styles.navbarLogoImg}
                src={locale[nodeLocale].logo}
                alt={locale[nodeLocale].logoText}
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="wtc-header-navbar-nav" />
          <Navbar.Collapse id="wtc-header-navbar-nav">
            <Nav className="ml-auto">
              {navMenuData.navigationItems.map((navItem, index) => (
                <NavigationItem
                  key={`nav-item-${navItem.id}`}
                  data={navItem}
                  mobileOnly={index !== 0}
                />
              ))}

              <Dropdown as={Nav.Item} className="d-none d-lg-block order-0">
                <Dropdown.Toggle as={Nav.Link}>
                  {navMenuData.exploreDropdownHeader} <ChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header
                    className={`${styles.navbarDropdownHeader} text-charcoal py-lg-1 px-lg-3`}
                  >
                    {navMenuData.navigationItems[1].title}
                  </Dropdown.Header>
                  {navMenuData.navigationItems[1].menuItems?.map((menuItem) => (
                    <Dropdown.Item
                      key={`nav-dropdown-service-item-${menuItem.id}`}
                      as={SmoothScrollLink}
                      to={menuItem.url}
                      activeClassName="active"
                    >
                      {menuItem.text}
                    </Dropdown.Item>
                  ))}

                  <Dropdown.Divider className="mx-3" />

                  <Dropdown.Header
                    className={`${styles.navbarDropdownHeader} text-charcoal py-lg-1 px-lg-3`}
                  >
                    {navMenuData.navigationItems[2].title}
                  </Dropdown.Header>
                  {navMenuData.navigationItems[2].menuItems?.map((menuItem) => (
                    <Dropdown.Item
                      key={`nav-dropdown-category-item-${menuItem.id}`}
                      as={SmoothScrollLink}
                      to={menuItem.url}
                      activeClassName="active"
                    >
                      {menuItem.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* Sign in */}
              <Nav.Item className="order-3 order-lg-0">
                <Nav.Link
                  href={`${
                    process.env.GATSBY_GREENSPACE_URL
                  }/login/?lang=${nodeLocale.toLowerCase()}`}
                >
                  {navMenuData.signIn}
                </Nav.Link>
              </Nav.Item>

              {/* Sign up */}
              <Nav.Item className="px-lg-2 my-2 my-lg-0 order-4 order-lg-0">
                <a
                  href={`${
                    process.env.GATSBY_GREENSPACE_URL
                  }/self_guided/signup/?lang=${nodeLocale.toLowerCase()}`}
                  className={`${styles.signupBtn} btn btn-success`}
                >
                  {navMenuData.createAccount}
                </a>
              </Nav.Item>

              {/* Language picker */}
              {navMenuData.showLanguageToggle && (
                <Nav.Item className="order-2 order-lg-0">
                  <LanguagePicker
                    activeLocale={nodeLocale}
                    locales={supportedLocales}
                  />
                </Nav.Item>
              )}

              {navMenuData.showSavedResources && (
                <TooltipTrigger
                  tooltipTitle={locale[nodeLocale].yourSavedResources}
                >
                  <Nav.Item className="d-flex align-items-center order-1 order-lg-0">
                    <Nav.Link
                      as={Link}
                      to={
                        nodeLocale === 'fr-CA'
                          ? '/fr-CA/favoris'
                          : '/en-CA/bookmarks'
                      }
                      activeClassName="active"
                      className="d-flex align-items-center pl-0 pr-2 pl-lg-2 pr-lg-0"
                    >
                      <span className="d-lg-none mr-2">
                        {navMenuData.savedResourcesText}
                      </span>
                      <span className="sr-only d-none d-lg-inline">
                        {navMenuData.savedResourcesText}
                      </span>
                      <Star aria-hidden="true" size="1.1rem" />
                    </Nav.Link>
                  </Nav.Item>
                </TooltipTrigger>
              )}

              {/* Program Navigator */}
              <Nav.Item className="d-lg-none">
                <Nav.Link
                  href="#"
                  active={false}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    setShowProgramNav(!showProgramNav);
                    clickContactProgramNavigator();
                  }}
                >
                  {navMenuData.whereToStartText}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

const NavigationItem = ({
  data,
  mobileOnly,
}: {
  data: NavigationItemData;
  mobileOnly: boolean;
}) => {
  const text = data.text || data.title;
  if (!text) return null;

  if (data.menuItems?.length) {
    return (
      <Dropdown
        as={Nav.Item}
        className={mobileOnly ? 'd-lg-none order-0' : 'order-0'}
      >
        <Dropdown.Toggle as={Nav.Link}>
          {text} <ChevronDown />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {data.menuItems.map((menuItem) => (
            <Dropdown.Item
              key={`nav-dropdown-item-${menuItem.id}`}
              as={SmoothScrollLink}
              to={menuItem.url}
              activeClassName="active"
            >
              {menuItem.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  if (data.url != null) {
    return (
      <Nav.Item>
        <Nav.Link
          as={SmoothScrollLink}
          to={data.url || ''}
          activeClassName="active"
        >
          {text}
        </Nav.Link>
      </Nav.Item>
    );
  }

  return <Nav.Item>{text}</Nav.Item>;
};

interface SupportedLocale {
  code: NodeLocale;
  name: string;
  slug: string;
}

interface LanguagePickerProps {
  activeLocale: NodeLocale;
  locales: SupportedLocale[];
}

const LanguagePicker = ({ activeLocale, locales }: LanguagePickerProps) => {
  const lastLocaleIndex = locales.length - 1;

  return (
    <div className="d-flex align-items-center">
      {locales.map(({ code, name, slug }, index) => (
        <div
          key={`language-picker-btn-wrapper-${code}`}
          className={`${styles.languagePickerBtnWrapper} d-flex align-items-center`}
        >
          <Link
            to={slug}
            className={`${styles.languagePickerBtn} ${
              activeLocale === code ? styles.active : ''
            }`}
            aria-label={
              name === 'EN'
                ? 'View site in English'
                : 'Afficher le site en français'
            }
          >
            {name}
          </Link>
          {index < lastLocaleIndex && (
            <span
              className={`${styles.languagePickerDivider} mx-1`}
              aria-hidden="true"
            ></span>
          )}
        </div>
      ))}
    </div>
  );
};

export const query = graphql`
  fragment NavigationMenu on ContentfulNavigationMenu {
    id
    nodeLocale: node_locale
    navigationItems {
      ... on ContentfulNavigationDropdown {
        id
        title
        menuItems {
          ...LinkedText
        }
      }
    }
    signIn
    createAccount
    savedResourcesText
    whereToStartText
    exploreDropdownHeader
    showLanguageToggle
    showSavedResources
  }
`;
