enum GAEventCategory {
  ENGAGEMENT = 'engagement',
  LANDING_PAGE = 'landing_page',
  RESOURCE_SELECTOR = 'resource_selector',
  RESOURCE_WRAPPER = 'resource_wrapper',
  CRISIS_PAGE = 'crisis_page',
}

/**
 * Send an event to Google Analytics
 *
 * @param action Name of the event
 * @param category Group of events
 * @param label Optional label that provides additional info about the event
 * @param value Optional numerical value that’s part of the event
 */
function send(
  action: string,
  category: GAEventCategory,
  label?: string,
  value?: number,
): void {
  // Some people block analytics, and we turn them off on dev, so sometimes
  // window.gtag is going to be undefined.
  if (!window.gtag) return;

  const params: Gtag.EventParams = {
    event_category: category,
    event_label: label,
    value,
  };
  window.gtag('event', action, params);
}

function createAction<T extends string | undefined>({
  action,
  category,
}: {
  action: string;
  category: GAEventCategory;
}) {
  type SendFnArgs = T extends string
    ? [label: T, value?: number]
    : [label?: string, value?: number];
  return (...[label, value]: SendFnArgs) => {
    send(action, category, label, value);
  };
}

const engagementEvents = {
  accessResource: createAction({
    action: 'access_resource',
    category: GAEventCategory.ENGAGEMENT,
  }),
};

const crisisPageEvents = {
  clickCrisisSupport: createAction<
    'adult' | 'youth' | 'frontline' | 'indigenous' | 'other'
  >({
    action: 'click_crisis_support',
    category: GAEventCategory.CRISIS_PAGE,
  }),
};

const landingPageEvents = {
  openProgramNavigator: createAction({
    action: 'open_program_navigator',
    category: GAEventCategory.LANDING_PAGE,
  }),
  clickNavigatorPhone: createAction({
    action: 'click_navigator_phone',
    category: GAEventCategory.LANDING_PAGE,
  }),
  clickCounsellingPhone: createAction<'adult' | 'youth'>({
    action: 'click_counselling_phone',
    category: GAEventCategory.LANDING_PAGE,
  }),
  clickCounsellingSms: createAction<'adult' | 'youth'>({
    action: 'click_counselling_sms',
    category: GAEventCategory.LANDING_PAGE,
  }),
  scrollTestimonials: createAction({
    action: 'scroll_testimonials',
    category: GAEventCategory.LANDING_PAGE,
  }),
  clickCrisisSupport: createAction<
    'adult' | 'youth' | 'frontline' | 'indigenous'
  >({
    action: 'click_crisis_support',
    category: GAEventCategory.LANDING_PAGE,
  }),
};

const GAEvent = {
  engagement: engagementEvents,
  crisisPage: crisisPageEvents,
  landingPage: landingPageEvents,
} as const;

export { GAEvent, engagementEvents, crisisPageEvents, landingPageEvents };
