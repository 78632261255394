import React from 'react';
import { GatsbyLinkProps, Link } from 'gatsby';

export const SmoothScrollLink = ({ ...props }: SmoothScrollLinkProps) => {
  if (/^https?:\/\//i.test(props.to)) {
    const anchorProps: Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'> = {};

    for (const [key, value] of Object.entries(props)) {
      if (!['activeClassName', 'activeStyle', 'to'].includes(key)) {
        // @ts-ignore
        anchorProps[key] = value;
      }
    }

    return (
      <a {...anchorProps} href={props.to}>
        {props.children}
      </a>
    );
  }

  const linkProps = {
    ...props,
  };

  if (props.to.includes('#')) {
    linkProps.onClick = (event) => {
      navTo(event, props.to);
      if (props.onClick) props.onClick(event);
    };
  }

  return <Link {...linkProps}>{props.children}</Link>;
};

const navTo = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
  // `ctrl + click` and `cmd/windows + click` opens the link in a new tab
  if (event.metaKey || event.ctrlKey) return;

  const parts = url.split('#');
  if (parts.length > 1) {
    const anchor = parts[1];
    const anchorTarget = document.getElementById(anchor);

    if (anchorTarget) {
      event.preventDefault();

      // A little above the element is nice
      let top = anchorTarget.offsetTop - 16;

      const topNav = document.getElementById('top-sticky-nav');
      if (topNav) {
        top -= topNav.clientHeight;
      }

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  }
};

type SmoothScrollLinkProps = Pick<
  GatsbyLinkProps<any>,
  'activeClassName' | 'activeStyle' | 'to' | 'onClick'
> &
  Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'>;
