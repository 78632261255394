import React, { useEffect, useState } from 'react';
import store from 'store';
import { Link } from 'gatsby';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import locale from '@util/locale';

export default function CookiePopup({ node_locale }) {
  const [show, toggleShow] = useState(false);
  function handleAccept() {
    toggleShow(false);
    store.set('personalization', true);
    store.set('analytics', true);
    store.set('cookies', true);
  }
  useEffect(() => {
    const cookies = store.get('cookies');
    if (typeof cookies === 'undefined') {
      setTimeout(() => toggleShow(true), 30000);
    }
  }, []);
  return !show ? null : (
    <Card className="cookies-banner">
      <Card.Body>
        <h2>{locale[node_locale].cookiePopupTitle}</h2>
        {locale[node_locale].cookiePopupCopy()}
        <Row>
          <Col xs={12} md={6}>
            <Button
              block
              className="mr-2"
              variant="primary"
              onClick={handleAccept}
            >
              {locale[node_locale].cookiePopupAccept}
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Link
              to={`/${node_locale}/cookies`}
              className="d-block btn btn-light text-primary"
            >
              {locale[node_locale].cookiePopupManage}
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
