import React from 'react';
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


interface TooltipTriggerProps extends OverlayTriggerProps {
  tooltipTitle: string;
}

export const TooltipTrigger = ({
  tooltipTitle,
  children,
  placement="top",
}: TooltipTriggerProps) => {
  const slug = tooltipTitle
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id={slug}>{tooltipTitle}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};
