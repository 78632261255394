import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import { TelephoneFill } from 'react-bootstrap-icons';
import { NodeLocale } from '@src/interfaces/NodeLocale';

export const CrisisTopbar = ({ link, nodeLocale }: CrisisTopbarProps) => {
  const { allContentfulCrisisBanner } = useStaticQuery<
    GraphQuery<'allContentfulCrisisBanner', CrisisBannerData>
  >(graphql`
    query CrisisBannerQuery {
      allContentfulCrisisBanner {
        edges {
          node {
            ...CrisisBanner
          }
        }
      }
    }
  `);
  const crisisBannerData = allContentfulCrisisBanner.edges.find(
    (edge) => edge.node.nodeLocale === nodeLocale,
  )!.node;

  return (
    <div className="bg-secondary">
      <Link
        to={link}
        className="d-flex d-md-none align-items-center justify-content-center bg-secondary text-white text-center p-1"
      >
        <TelephoneFill className="mr-2" />
        <span>{crisisBannerData.cta}</span>
      </Link>

      <Container className="d-none d-md-block">
        <div className="d-flex align-items-center justify-content-end py-2">
          <div className="text-white small">{crisisBannerData.question}</div>
          <Link
            to={link}
            className="btn btn-primary rounded-pill ml-2 d-inline-flex align-items-center"
          >
            <TelephoneFill className="mr-2" />
            <span>{crisisBannerData.cta}</span>
          </Link>
        </div>
      </Container>
    </div>
  );
};

interface CrisisTopbarProps extends React.HTMLProps<HTMLDivElement> {
  link: string;
  nodeLocale: NodeLocale;
}

type GraphQuery<K extends string | number | symbol, T> = {
  [P in K]: {
    edges: {
      node: T;
    }[];
  };
};

export interface CrisisBannerData {
  nodeLocale: NodeLocale;
  question: string;
  cta: string;
}

export const query = graphql`
  fragment CrisisBanner on ContentfulCrisisBanner {
    nodeLocale: node_locale
    question
    cta
  }
`;
