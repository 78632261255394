import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as styles from '@css/modules/footer.module.scss';
import primaryCheck from '@css/img/check-primary.png';

export default function Footer({ node_locale, page }) {
  const { allContentfulFooter } = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter {
        edges {
          node {
            node_locale
            header
            headerLineTwo
            benefits
            ctaCopy
            ctaUrl
            subTitle {
              subTitle
            }
          }
        }
      }
    }
  `);
  const { node } = allContentfulFooter.edges.find(
    (edge) => edge.node.node_locale === node_locale,
  );
  return (
    <section
      className={`${styles.wrapper} ${
        page === 'landing' ? 'bg-secondary' : ''
      }`}
    >
      <span className={styles.track} />
      <div className={styles.parent}>
        <Container fluid>
          <Row className={styles.row}>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <h2 className={styles.header}>
                {node.header}
                <br />
                {node.headerLineTwo}
              </h2>
              <p>{node.subTitle.subTitle}</p>
              <div className={styles.checklist}>
                {node.benefits.map((benefit, i) => (
                  <span className={styles.benefit} key={i}>
                    <img src={primaryCheck} alt="" />
                    {benefit}
                  </span>
                ))}
              </div>
              <div className={styles.cta}>
                <a
                  href={`${
                    process.env.GATSBY_GREENSPACE_URL
                  }/self_guided/signup/?lang=${node_locale.toLowerCase()}`}
                  className={`btn btn-primary ${styles.ctaButton} btn-dark-shadow`}
                >
                  {node.ctaCopy}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
