import { useMixpanel } from 'gatsby-plugin-mixpanel';

export interface MPEventData {
  language: 'en-ca' | 'fr-ca' | 'en-CA' | 'fr-CA';
  url: string;
  url_name: string;
}

export interface DefaultMPEventData extends MPEventData {
  datacenter_country: 'CA';
  domain: 'wellnesstogether.ca';
  site_slug: 'canada'; // The site slug is the whitelabel slug
}

export interface PageInteractionEventData extends MPEventData {
  element: string;
  action: string;
  value?: string;
}

export interface ResourceEventData extends MPEventData {
  id: string;
  name: string;
  slug: string;
  provider: string;
  source: 'resource-wrapper' | 'home';
  external: boolean;
  url_name: 'resource-wrapper' | 'home';
}

export interface FilterResourceEventData extends MPEventData {
  action: string;
  source: string;
  age: string;
  topics: string;
  support_type: string;
}

export interface UnfilterResourceEventData extends MPEventData {
  source: string;
  type: string;
}

export interface ResourceFeedbackEventData extends ResourceEventData {
  question: string;
  value: 'yes' | 'no';
}

const trackingData: DefaultMPEventData = {
  language: 'en-ca',
  datacenter_country: 'CA',
  domain: 'wellnesstogether.ca',
  site_slug: 'canada', // The site slug is the whitelabel slug
  url: '/',
  url_name: 'home',
};

/* eslint-disable import/prefer-default-export */
export function useMPEvent() {
  const mixpanel = useMixpanel();
  function createEvent<T extends MPEventData>(eventName: string) {
    return (eventData: T) => {
      const data = { ...trackingData, ...eventData };
      data.language = data.language.toLowerCase() as 'en-ca' | 'fr-ca';
      mixpanel.track(eventName, data);
    };
  }

  return {
    trackPageLoad: createEvent<MPEventData>('Page Load'),
    trackPageInteraction: createEvent<PageInteractionEventData>(
      'Page Interaction',
    ),
    trackAccessResource: createEvent<ResourceEventData>('Access Resource'),
    trackFilterResource: createEvent<FilterResourceEventData>(
      'Filter Resource',
    ),
    trackUnfilterResource: createEvent<UnfilterResourceEventData>(
      'Unfilter Resource',
    ),
    trackAddBookmarkResource: createEvent<ResourceEventData>('Save Resource'),
    trackRemoveBookmarkResource: createEvent<ResourceEventData>(
      'Remove Bookmark Resource',
    ),
    trackResourceFeedback: createEvent<ResourceFeedbackEventData>(
      'Submit Resource Feedback',
    ),
  };
}
