import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import store from 'store';
import eventsPlugin from 'store/plugins/events';

import { Helmet } from 'react-helmet';
import Footer from '@components/layout/Footer';
import BottomBanner from '@components/layout/BottomBanner';
import SaveBookmarkContext from '@components/bookmarks/SaveBookmarkContext';
import ConfirmSave from '@components/bookmarks/ConfirmSave';
import CookiePopup from '@components/modals/CookiePopup';
import * as styles from '@css/modules/page-layout.module.scss';
import locale from '@util/locale';
import { NavigationMenu } from './NavigationMenu';

store.addPlugin(eventsPlugin);

export default function PageLayout({
  children,
  node_locale: nodeLocale,
  page,
  pageTitle,
  footer,
  translations,
}) {
  const personalization = store.get('personalization');
  const analytics = store.get('analytics');
  const [seenBookmarkModal, toggleSeenBookmarkModal] = useState(false);
  const [showBookmarkModal, toggleShowBookmarkModal] = useState(false);
  const [cookieChoice, toggleCookieChoice] = useState(undefined);

  const { allContentfulOpenGraphMeta } = useStaticQuery(graphql`
    query openGraphMetaQuery {
      allContentfulOpenGraphMeta {
        edges {
          node {
            node_locale
            title
            description {
              description
            }
            openGraphImg {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const { node: openGraphMeta } = allContentfulOpenGraphMeta.edges.find(
    (edge) => edge.node.node_locale === nodeLocale,
  );
  const location = useLocation();

  store.watch('cookies', () => {
    // cookies: if defined then user has seen the pop up and done one of:
    // 1: clicked Accept
    // 2: visited the cookies page
    const cookieValue = store.get('cookies');
    toggleCookieChoice(cookieValue);
  });

  useEffect(() => {
    if (typeof personalization === 'undefined')
      store.set('personalization', true);
    if (typeof analytics === 'undefined') store.set('analytics', true);
    const cookies = store.get('cookies');
    if (cookies) toggleCookieChoice(cookies);
  }, []);

  useEffect(() => {
    const bookmarkStorage = store.get('bookmarkStorage');
    if (bookmarkStorage && bookmarkStorage === 'local')
      toggleSeenBookmarkModal(true);
  }, []);

  const metaTitle = pageTitle
    ? `${openGraphMeta.title} | ${pageTitle}`
    : openGraphMeta.title;

  return (
    <div className="pageWrapper">
      <Helmet htmlAttributes={{ lang: nodeLocale }}>
        <title>{metaTitle}</title>
        <meta property="og:url" content={location.href} />
        <meta property="og:title" content={metaTitle} />
        <meta
          property="og:description"
          content={openGraphMeta.description.description}
        />
        <meta
          property="og:image"
          content={openGraphMeta.openGraphImg.file.url}
        />
        <meta
          property="og:locale"
          content={openGraphMeta.openGraphImg.node_locale}
        />
        <meta
          property="og:type"
          content={
            ['category', 'resource-wrapper'].includes(page)
              ? 'article'
              : 'website'
          }
        />
      </Helmet>

      <a
        href="#main"
        className={`btn btn-outline-primary border-0 ${styles.skipLink}`}
      >
        {locale[nodeLocale].skipLink}
      </a>

      <NavigationMenu
        nodeLocale={nodeLocale}
        page={page}
        translations={translations}
      />

      <SaveBookmarkContext.Provider
        value={{
          seen: seenBookmarkModal,
          toggleSeen: toggleSeenBookmarkModal,
          show: showBookmarkModal,
          toggleShow: toggleShowBookmarkModal,
        }}
      >
        <main id="main" className={`${page} contentWrapper`}>
          {children}
        </main>
        <ConfirmSave node_locale={nodeLocale} />
      </SaveBookmarkContext.Provider>

      <Footer data={footer} node_locale={nodeLocale} page={page} />

      <BottomBanner node_locale={nodeLocale} />

      {typeof cookieChoice === 'undefined' && page !== 'cookies' ? (
        <CookiePopup node_locale={nodeLocale} />
      ) : null}
    </div>
  );
}
