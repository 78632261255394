import React from 'react';
import ReactModal from 'react-modal';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TelephoneFill, X } from 'react-bootstrap-icons';

import * as styles from '@css/modules/program-nav.module.scss';
import { GAEvent } from '@src/util/analytics/GAEvent';
import { useMPEvent } from '@util/mixpanel';

export default function ProgramNavigator({
  node_locale: nodeLocale,
  show,
  setShow,
}) {
  const isFrench = nodeLocale === 'fr-CA';

  const node = isFrench
    ? {
        title: 'Contactez un gestionnaire de programme',
        action: 'Appelez le',
        phoneNumber: '1-866-585-0445',
        copy:
          'Pour parler à un gestionnaire de programme qui saura vous aider à trouver des ressources utiles et le soutien dont vous avez besoin sur le portail.',
        ctaButton: 'Fermer',
      }
    : {
        title: 'Contact a Program Navigator',
        action: 'Call',
        phoneNumber: '1-866-585-0445',
        copy:
          'To speak with a Program Navigator who can help you find the resources and support on the portal that would be most helpful for you.',
        ctaButton: 'Close',
      };

  const { trackPageInteraction } = useMPEvent();

  function hideModal() {
    setShow(false);
  }

  function clickCounsellingPhone() {
    trackPageInteraction({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/`,
      url_name: 'program_navigator_modal',
      element: 'navigator phone',
      action: 'click',
    });
    GAEvent.landingPage.clickNavigatorPhone();
  }

  return typeof window !== 'undefined' ? (
    <ReactModal
      isOpen={show}
      onRequestClose={hideModal}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <div className={styles.body}>
        <Row>
          <Col xs={12} className="mb-2">
            <div className={styles.header}>
              <h5>{node.title}</h5>
              <X
                role="button"
                aria-label="Close this modal"
                className={styles.closeIcon}
                onClick={hideModal}
              />
            </div>
          </Col>
        </Row>
        <Row className={styles.mobileActionParent}>
          <Col xs={12}>
            <span className={styles.mobileAction}>{node.action}</span>
          </Col>
        </Row>
        <Row className={styles.phoneParent}>
          <Col xs={1} className={styles.iconParent}>
            <TelephoneFill className={styles.icon} />
          </Col>
          <Col xs={12} md={11}>
            <p className={styles.actionParent}>
              <TelephoneFill className={styles.iconMobile} />
              <span className={styles.action}>{node.action}</span>{' '}
              <a
                href={`tel:${node.phoneNumber}`}
                className={styles.phoneNumber}
                onClick={() => clickCounsellingPhone()}
              >
                {node.phoneNumber}
              </a>
            </p>
          </Col>
          <Col xs={12}>
            <p>{node.copy}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button block centered="true" onClick={hideModal}>
              {node.ctaButton}
            </Button>
          </Col>
        </Row>
      </div>
    </ReactModal>
  ) : null;
}
