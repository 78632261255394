import React from 'react';
import { Link } from 'gatsby';
import enLogo from '@css/img/logo/en.png';
import frLogo from '@css/img/logo/fr.png';
import enLogoCropped from '@css/img/logo/en-cropped.png';
import frLogoCropped from '@css/img/logo/fr-cropped.png';


// TODO migrate copy to contentful!
// NB: while a good idea, some of this copy is very generic and would be complex to migrate and should actually be left here
// EG: yes/no, "About this resource" appears on every resource without changing, and so forth

const locale = {
  'en-CA': {
    logo: enLogo,
    mobileLogo: enLogoCropped,
    open: 'open',
    close: 'close',
    skipLink: 'Skip to content',
    save: 'Save this resource',
    saved: 'Saved this resource!',
    about: 'About this resource',
    whatItDoes: 'What It Does',
    howItHelps: 'How It Helps',
    mightHelp: 'These other resources might be helpful',
    tryThese: 'You might like to try these resources',
    helpful: 'Did you find this resource useful?',
    feedback_thankyou: 'Thank you. Your feedback is important to us.',
    feedback: {
      yes: {
        header:
          'Thank you for your feedback. Can you tell us what you liked about this resource?',
        options: [
          'It was easy to use ',
          'It was comforting to me',
          'It helped me understand what I’m experiencing ',
          'It helped me calm down ',
          'It made me feel less alone',
          'It made me feel hopeful that I can change my situation',
        ],
      },
      no: {
        header:
          'Help us improve your experience. Can you tell us why this resource wasn’t helpful?',
        options: [
          'It wasn’t what I was expecting',
          'It didn’t suit my needs ',
          'It didn’t resonate with me',
          'It didn’t apply to my situation',
          'I didn’t understand how to use it / It was difficult to navigate',
        ],
      },
    },
    yes: 'Yes',
    no: 'No',
    sendFeedback: 'Send Feedback',
    necessaryCookiesHeader: 'Necessary Cookies',
    necessaryCookiesBody:
      'The necessary cookies help us provide the core functionality of the website and collect basic aggregated statistics about how the website and app are used. While you cannot opt-out of these cookies, you can disable all cookies by changing your browser settings. However, disabling cookies will affect how the website functions.',
    cookiePopupTitle: 'We value your privacy',
    cookiePopupCopy: () => (
      <p className="cookies-copy">
        We use cookies to support you better. Learn more by reading our{' '}
        <Link className="text-secondary" to={`/en-CA/privacy`}>
          privacy page
        </Link>
        , or click the button below to manage your cookie preferences.
      </p>
    ),
    cookiePopupAccept: 'Accept',
    cookiePopupManage: 'Manage my cookies',
    crisis: 'In Crisis?',
    yourSavedResources: 'Your saved resources',
    closeProgramNavigationModal: 'Close the program navigator modal',
    openProgramNavigationModal: 'Open the program navigator modal',
    logoText: 'Logo of Wellness Together Canada',
    slide: 'Slide',
    nextSlide: 'Next slide',
    previousSlide: 'Previous slide',
  },
  'fr-CA': {
    logo: frLogo,
    mobileLogo: frLogoCropped,
    open: 'ouvrir',
    close: 'fermer',
    skipLink: 'Passer au contenu principal',
    save: 'Sauvegardez cette ressource',
    saved: 'Ressource sauvegardez!',
    about: 'À propos de cette ressource',
    whatItDoes: `De quoi il s'agit`,
    howItHelps: 'Comment ça aide',
    mightHelp: 'Ces autres resources pourraient vous étre utile',
    tryThese: 'Vous aimerez peut-être consulter ces ressources',
    helpful: 'Avez-vous trouvé cette ressource utile?',
    feedback_thankyou: 'Merci pour votre opinion!',
    feedback: {
      yes: {
        header:
          'Merci pour vos commentaires. Pouvez-vous nous dire ce que vous avez apprécié à propos de cette ressource?',
        options: [
          'Elle était facile à utiliser',
          "Elle m'a réconforté",
          "Elle m'a aidé à mieux comprendre ce que je vis",
          "Elle m'a aidé à me calmer",
          "Elle m'a permis de me sentir moins seul",
          "Elle m'a redonné de l'espoir",
        ],
      },
      no: {
        header:
          "Aidez-nous à améliorer votre expérience. Pouvez-vous nous dire pourquoi cette ressource n'a pas été utile?",
        options: [
          "Ce n'était pas ce à quoi je m’attendais",
          'Elle ne répondait pas à mes besoins ',
          'Elle ne m’interpellait pas',
          "Elle ne s'appliquait pas à ma situation",
          "Je n'ai pas compris comment l'utiliser / La navigation était difficile",
        ],
      },
    },
    yes: 'Oui',
    no: 'Non',
    sendFeedback: 'Envoyer',
    necessaryCookiesHeader: 'Cookies Nécessaire',
    necessaryCookiesBody: `Les cookies nécessaires aident notre site Web à fonctionner correctement. Ces outils nous donnent des statistiques agrégées sur la façon dont le site et l'application sont utilisée.\n\nBien que vous ne puissiez pas refuser ces cookies à partir du site Web, vous pouvez désactiver tous les cookies en modifiant les paramètres de votre navigateur. Toutefois, la désactivation des cookies affectera le fonctionnement du site Web.`,
    cookiePopupTitle: 'Nous respectons votre vie privée',
    cookiePopupCopy: () => (
      <p className="cookies-copy">
        Nous utilisons des cookies pour mieux vous aider. Apprenez-en davantage
        en consultant notre{' '}
        <Link className="text-secondary" to={`/fr-CA/privacy`}>
          page sur la confidentialité
        </Link>
        , ou cliquez sur le bouton ci-dessous pour gérer vos préférences en
        matière de cookies.
      </p>
    ),
    cookiePopupAccept: 'Accepter',
    cookiePopupManage: 'Gérer mes cookies',
    crisis: 'En Crise?',
    yourSavedResources: 'Vos ressources enregistrées',
    closeProgramNavigationModal: 'Fermer la fenêtre du gestionnaire de programme',
    openProgramNavigationModal: 'Afficher la fenêtre du gestionnaire de programme',
    logoText: "Logo d'Espace Mieux-être Canada",
    slide: 'Diapositive',
    nextSlide: 'Diapositive suivante',
    previousSlide: 'Diapositive précédente',
  },
};

export default locale;
